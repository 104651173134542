<template>
  <div>
    <el-row>
      <el-button icon="el-icon-download">导出</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form inline :model="lossAccountCondition">
        <!-- 编号/名称 -->
        <el-form-item
          class="formItemBoxStyle"
          label="编号/名称"
          prop="materialCodeName"
        >
          <el-input
            style="width: 200px"
            v-model="lossAccountCondition.materialCodeName"
            placeholder="请输入"
            @blur="handleQueryAccountTable"
          />
        </el-form-item>
        <!-- 报损报失类型 -->
        <el-form-item class="formItemBoxStyle" label="报损报失分类">
          <el-select
            style="width: 200px"
            v-model="lossAccountCondition.operateType"
            @change="handleQueryAccountTable"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="报失" :value="1"></el-option>
            <el-option label="报损" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <!-- 危化品 -->
        <el-form-item label="是否危化品" class="formItemBoxStyle">
          <el-select
            v-model="lossAccountCondition.hasDanger"
            @change="handleQueryAccountTable"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- 消耗品 -->
        <el-form-item label="是否消耗品" class="formItemBoxStyle">
          <el-select
            v-model="lossAccountCondition.hasConsumables"
            @change="handleQueryAccountTable"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- 日期 -->
        <el-form-item class="formItemBoxStyle" label="日期">
          <div class="block">
            <el-date-picker
              v-model="queryTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>
        <!-- 编号/名称 -->
        <el-form-item
          class="formItemBoxStyle"
          label="报损报失批次"
          prop="batchCode"
        >
          <el-input
            style="width: 200px"
            v-model="lossAccountCondition.batchCode"
            placeholder="请输入"
            @blur="handleQueryAccountTable"
          />
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item>
          <el-button @click="handleResetAccountTable">重置</el-button>
          <el-button @click="handleQueryAccountTable" type="primary"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 表格区域 -->
    <el-table
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{ 'text-align': 'center' }"
      :data="lossAccountTableList"
    >
      <el-table-column label="国标编号" prop="internationCode">
        <template slot-scope="scoped">
          <span>{{ scoped.row.internationCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="materialName">
        <template slot-scope="scoped">
          <span
            @click="handleCheckMaterialStockInfo(scoped.row)"
            style="cursor: pointer; color: #00f; text-decoration: underline"
            >{{ scoped.row.materialName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="materialHasConsumables" label="是否消耗品" center>
        <template slot-scope="scoped">
          {{ scoped.row.materialHasConsumables ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="materialHasDanger" label="是否危化品" center>
        <template slot-scope="scoped">
          {{ scoped.row.materialHasDanger ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="materialSpecifications" label="规格型号功能">
      </el-table-column>
      <el-table-column prop="materialUnit" label="单位"> </el-table-column>
      <el-table-column
        :formatter="customFormatter"
        prop="submitTime"
        label="日期"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="operateType"
        :formatter="customFormatter"
        label="报损报失分类"
      ></el-table-column>
      <el-table-column prop="batchCode" label="报损报失编号"></el-table-column>
      <el-table-column prop="lossNum" label="报损报失数量"> </el-table-column>
      <el-table-column
        prop="reason"
        label="原因"
        width="300"
      ></el-table-column>
      <el-table-column prop="submitter" label="操作人"></el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangelossAccountTableMsg"
        @current-change="handleCurrentChangelossAccountTableMsg"
        :current-page="lossAccountPageInfo.pageIndex"
        :page-sizes="[5, 10, 20, 50, 100]"
        :page-size="lossAccountPageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="lossAccountPageInfo.pageTotal"
      ></el-pagination>
    </el-row>
    <!-- 查看实验设备详情弹框 -->
    <el-dialog
      title="查看实验设备详情"
      :visible.sync="checkMaterialStockInfoDialogVisible"
    >
      <checkMaterialCom
        :stockInfo="materialStockInfo"
        :imgUrl="editDialogImageList"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getStockEquipmentDetailApi } from "@/api/materialManage/materialAccount.js";
import { getMaterialLossAccountInfoApi } from "@/api/materialManage/lossReport.js";
import { isEmpty, formatDate } from "@/utils/util.js";
import checkMaterialCom from "../components/checkMaterialCom.vue";
import { getStore } from '@/utils/token.js';
export default {
  components: {
    checkMaterialCom,
  },
  data() {
    return {
      lossAccountCondition: {
        materialCodeName: "",
        operateType: "",
        hasConsumables: "",
        hasDanger: "",
        startTime: "",
        endTime: "",
        batchCode: "",
        sysOrgSchoolId:0,
      },
      lossAccountPageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      lossAccountTableList: [],
      queryTime: "",

      materialStockInfo: {
        affiliatedUnit: "",
        internationCode: "",
        materialHasDanger: "",
        materialHasConsumables: "",
        materialSpecifications: "",
        subjectDicId: "",
        materialUnitPrice: "",
        equipRequir: "",
        keeper: "",
        placeRoomId: "",
        snCode: "",
        materialFeature: "", // 物品特征
      },
      editDialogImageList: [],
      checkMaterialStockInfoDialogVisible: false,
      sysOrgSchoolId:0,
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"))
    this.lossAccountCondition.sysOrgSchoolId=this.sysOrgSchoolId
    this.fetchlossAccountData();
  },
  methods: {
    // 获取报损报失总账数据
    fetchlossAccountData() {
      getMaterialLossAccountInfoApi(
        this.lossAccountPageInfo.pageIndex,
        this.lossAccountPageInfo.pageSize,
        this.lossAccountCondition
      ).then((res) => {
        if (res.success) {
          this.lossAccountTableList = res.data;
          this.lossAccountPageInfo.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 查询报损报失总账
    handleQueryAccountTable() {
      this.lossAccountPageInfo.pageIndex = 1;
      this.lossAccountPageInfo.pageSize = 10;
      this.fetchlossAccountData();
    },
    // 重置报损报失总账
    handleResetAccountTable() {
      this.lossAccountCondition = {
        materialCodeName: "",
        operateType: "",
        hasConsumables: "",
        hasDanger: "",
        startTime: "",
        endTime: "",
        batchCode: "",
        sysOrgSchoolId:this.sysOrgSchoolId,
      };
      this.queryTime = "";
      this.handleQueryAccountTable();
    },
    // 日期选择
    queryDateChange(value) {
      if (value.length > 0) {
        this.lossAccountCondition.startTime = Date.parse(value[0]);
        this.lossAccountCondition.endTime = Date.parse(value[1]);
      }
      this.handleQueryAccountTable();
    },
    handleSizeChangelossAccountTableMsg(val) {
      this.lossAccountPageInfo.pageIndex = 1;
      this.lossAccountPageInfo.pageSize = val;
      this.fetchlossAccountData();
    },
    handleCurrentChangelossAccountTableMsg(val) {
      this.lossAccountPageInfo.pageIndex = val;
      this.fetchlossAccountData();
    },
    // 查看耗材详情
    handleCheckMaterialStockInfo(row) {
      this.editDialogImageList = [];
      let fd = new FormData();
      fd.append("sysExpConsumeMaterialId", row.sysExpConsumeMaterialId);
      fd.append("sysOrgSchoolId",this.sysOrgSchoolId);
      // fd.append("roomId", row.roomId ? row.roomId : 0);
      getStockEquipmentDetailApi(fd).then((res) => {
        if (res.success) {
          const { data } = res;
          this.editDialogImageList = [];
          this.materialStockInfo = {
            materialName: data.materialName,
            affiliatedUnit: data.tenantId,
            internationCode: data.internationCode,
            materialHasDanger: data.materialHasDanger ? "是" : "否",
            materialHasConsumables: data.materialHasConsumables ? "是" : "否",
            materialSpecifications: data.materialSpecifications,
            subjectDicId: data.subjectTypeDicName,
            materialUnitPrice: data.materialUnitPrice,
            equipRequire: data.equipRequire == 1 ? "基本" : "选配",
            keeper: data.keeper,
            placeRoomId: data.roomName,
            snCode: data.snCode,
            materialFeature: data.materialItemTagDTOS, // 物品特征
          };
          data.imgUrl.forEach((item) => {
            this.editDialogImageList.push(this.$imageAddress(item));
          });
          this.checkMaterialStockInfoDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return;
      }
      switch (column.property) {
        case "submitTime":
          if (isEmpty(row.submitTime)) {
            return "/";
          }
          return formatDate(new Date(row.submitTime), "yyyy/MM/dd");
        case "operateType":
          switch (row.operateType) {
            case 0:
              return "报损";
            case 1:
              return "报失";
          }
      }
    },
  },
};
</script>

<style></style>
