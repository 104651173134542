import { request, noTimeOutReq } from "@/utils/request.js";

// 新增【报损失单】
export function addStockLossApi(data) {
  return request({
    url: `/eqpt/stock/loss/add`,
    method: "post",
    data,
  });
}

// 分页查询【报损失单清单】
export function getMaterialLossAccountInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/loss/detail/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}

// 查询【报损失单详情】
export function getStockLossDetailApi(stockLossId) {
  return request({
    url: `/eqpt/stock/loss/get`,
    method: "post",
    params:{
      stockLossId
    },
  });
}

// 分页查询【报损失单】
export function getMaterialLossInfoApi(pageIndex, pageSize, data) {
  return request({
    url: `/eqpt/stock/loss/page/${pageIndex}/${pageSize}/list`,
    method: "post",
    data,
  });
}
